.footer {
    background-color: rgb(83, 116, 241);
    position: fixed;
    width: 100%;
    bottom: 0px;
    z-index: 1;
}

.footer>img {
    width: 170px;
    padding: 7px 0px 5px 0px;
}

.footer .footer-logo-mobile {
    width: 37px;
    margin: 0px 0px 0px 15px;
}

.label-test {
    background-color: rgb(83, 116, 241) !important;
    color: white !important;
    /* padding: 15px 15px 5px !important;
    v */
    vertical-align: bottom !important;
    padding-top: 12px !important;
    font-weight: 300 !important;
    height: 36px;
}

.report-bug-icon {
    cursor: pointer;
}