.segment.heading {
    min-height: var(--segmentHeadingMinHeight, 700px);
    padding: 1em 0em;
}

.segment.heading.mobile {
    min-height: var(--segmentHeadingMinHeight, 350px);
    padding: 0px !important;
}

.pusher.heading {
    min-height: 100vh;
}

.heading.mobile {
    background-color: rebeccapurple !important;
}

.ui.uncover.sidebar {
    z-index: 5 !important;
}