body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
    /* background-color: rgba(245, 242, 240, 1); */
    background-color: rgba(231, 236, 239, 1);
}

.ui.divider:not(.vertical):not(.horizontal) {
    border-top: 1px solid rgba(119, 136, 153, .15) !important;
}

.ui.menu {
    background: #fff;
}

@media print {
    body * {
        visibility: hidden;
    }

    .invoice-print-container {
        width: 100% !important;
    }

    .invoice-print * {
        visibility: visible;
    }

    .invoice-print {
        position: absolute;
        left: 0;
        top: 0;
    }
}