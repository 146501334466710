.negate-menu-padding {
    margin-top: -70px;
}

/**
* Styles for tables that shows lists of items.
*/

.item-list-container {
    margin: 0px 30px;
}

.item-list-container tbody {
    display: block;
    height: 75vh;
    overflow: auto;
}

.item-list-container .widget {
    margin-bottom: 15px;
}

.item-list-container .widget table {
    display: block;
    height: 250px;
    overflow: auto;
}

.item-list-container .widget tbody {
    height: auto;
}

.item-list-container thead,
.item-list-container tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
}

.item-list-container tbody tr {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.item-table-container {
    margin-bottom: 50px;
}

/* Mobile */

.mobile-item-list-container {
    max-height: 70vh;
    overflow: auto;
}

.mobile-item-list-header {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: normal;
    align-content: normal;
    margin-bottom: 10px;
}

.mobile-item-list-header .mobile-items {
    display: block;
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: auto;
    align-self: auto;
    align-items: center;
    order: 0;
}

.mobile-item-list {
    margin: 5% 10% 20%;
}

.mobile-item-table {
    margin-bottom: 20%;
}

@media screen and (max-width: 767px) {
    .form-fields {
        margin: 15px 0px !important;
    }
}

/**
* Common component styles.
*/

.icon-link {
    cursor: pointer;
}

.form-right-toolbar-container,
.form-left-toolbar-container {
    margin-bottom: 2% !important;
}

.ButtonWrap__root___1EO_R {
    z-index: 5 !important;
}

.section-form-container {
    margin-bottom: 15%;
}

.mobile-heading .segment {
    padding: 0px !important
}

.release-notes-link {
    text-decoration: underline;
    margin-left: 5px;
    cursor: pointer;
}

.popover-modal-view {
    filter: blur(0) !important;
}

.api-key-features-modal-table .scrollable tbody {
    height: 100%;
}