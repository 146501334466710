.ui.menu .create-button .item {
    color: white;
}

.create-button .ui.button>.icon:not(.button) {
    height: 1.85714286em;
    width: 1.85714286em;
    padding: .5em;
    background-color: green;
    border-radius: 6px;
}

.ui.menu .create-button .header {
    cursor: default;
}

.ui.menu .create-button .item ::selection {
    color: white;
}